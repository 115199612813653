@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 15px;
}

code,
pre {
  font-family: "Roboto Mono", "Source Code Pro", monospace;
  font-size: 0.9em;
}

code {
  padding-left: 4px;
  padding-right: 4px;
  background-color: hsl(222, 12%, 90%);
  word-break: break-all;
}

ol,
ul {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  list-style-position: inside;
  text-indent: -1.3em;
  padding-left: 1.3em;
}

li {
  line-height: 1.82;
}

.katex {
  padding-left: 2px;
  padding-right: 2px;
}
